(function ($) {
Drupal.behaviors.cliniqueCommonPgDate = {
  attach: function (context, settings) {
    var cookieVal = $.cookie('today_param') || 'Today';
    $('.pg-date-selected').text(cookieVal);
    // Date set click handler
    $('.pg-date-cookie li a').not('.pg-date-reset a').on('click', function(event) {
      event.preventDefault();
      var expires = new Date();
      expires.setTime(expires.getTime() + (60 * 60 * 24 * 365));
      $.cookie('today_param', $(this).text(), { expires : expires, path : '/' });
      location.reload();
    });
    // Date reset click handler
    $('.pg-date-cookie li.pg-date-reset a').on('click', function(event) {
      event.preventDefault();
      $.removeCookie('today_param');
      location.reload();
    });
  }
};

})(jQuery);
